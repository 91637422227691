.header {
  position: fixed;
  // position: sticky;
  top: 0;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  background-color: var(--surface-0);
}

.p-dialog-header {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.header__logo img {
  max-height: 48px;
}

.header__buttons button {
  margin-left: 1rem;
}

.hamburger-icon {
  font-size: 1.5rem;
}

.p-dialog-header-icons {
  align-self: center !important;
}
.draft-detail .p-dialog-header-icons {
  align-self: flex-start !important;
  margin-top: 1rem;
}

.draft-detail .new-application-vertical-steps .p-steps ul li {
  padding-top: 1rem;
}

.header__buttons .login-button {
  background-color: #dff4ff;
  border: 1px solid #dff4ff;
  width: 100px;
  height: 45px;
  margin-right: 0.75rem;
}

.header__buttons .login-button:enabled:hover {
  background: #e3f6ff;
  color: #ffffff;
  border-color: #e3f6ff;
}

.header__buttons .login-button span {
  color: #4e90cd;
  font-weight: 600;
}

.header__buttons .make-an-application-button {
  background: linear-gradient(180deg, #4e90cd 0%, #0085ff 100%);
  border: 0px;
  width: 180px;
  height: 45px;
  margin-right: 1.75rem;
}

.make-new-application-button {
  position: absolute;
  right: 50px;
  z-index: 999;
  top: 20px;
}
