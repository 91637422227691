.div-authcode input {
  width: 3.75rem;
  height: 3.625rem;
  font-size: 2rem;
  text-align: center;
  margin-right: 1rem;
  text-transform: uppercase;
  color: var(--soft-green);
  border-radius: 8px;
  background: #fff;
  background-clip: padding-box;
  padding: 8px 16px;
  border: 1px solid #dadada;
}
.div-authcode input::placeholder {
  color: #999;
  font-style: italic;
}
//max-width 768px
@media only screen and (max-width: 768px) {
  .div-authcode input {
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
    margin-right: 0.5rem;
  }
}


.forgot-password-desc {
  color: #abafb1;
}

.login-logo {
  display: block;
  // background-color: rgba(216, 121, 155, 0.46);
  mix-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../images/login_bg_1.png");
  mix-blend-mode: darken;
  animation: slide_login 10s infinite;
}

@keyframes slide_login {
  0% {
    background-image: url("../../images/login_bg_1.png");
  }
  33% {
    background-image: url("../../images/login_bg_2.png");
  }
  67% {
    background-image: url("../../images/login_bg_3.png");
  }
}

@media only screen and (max-width: 991px) {
  .login-logo {
    display: none;
  }
}

.login-input-container {
  padding-left: 8rem;
  padding-right: 8rem;
  height: 100%;
  background-color: #f9f9f9;
}

.login-label {
  color: var(--text-color);
}

@media only screen and (max-width: 1399px) {
  .login-input-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 991px) {
  .login-input-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
