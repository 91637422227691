@media (min-width: 992px) {
  .layout-wrapper {
    &.layout-overlay {
      // .layout-main-container {
      //     margin-left: 0;
      //     padding-left: 2rem;
      // }

      .layout-sidebar {
        transform: translateX(-100%);
        left: 0;
        top: 0;
        height: 100vh;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.layout-overlay-sidebar-active {
        .layout-sidebar {
          transform: translateX(0);
        }
      }
    }

    .layout-mask {
      display: none;
    }
  }

  .p-paginator button {
    margin: 0 3px !important;
    border-radius: 5px !important;
  }

  .mobile-button-group {
    margin-right: 0.5rem !important;
  }

  .w-20 {
    width: 20% !important;
  }
}

@media (max-width: 991px) {
  .layout-wrapper {
    // .layout-main-container {
    //     margin-left: 0;
    //     padding-left: 2rem;
    // }

    .layout-sidebar {
      // transform: translateX(-100%);
      position: absolute;
      left: -300px;
      top: 0;
      height: 100vh;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .layout-mask {
      z-index: 998;
      background-color: var(--mask-bg);

      &.layout-mask-enter-from,
      &.layout-mask-leave-to {
        background-color: transparent;
      }
    }

    &.layout-mobile-sidebar-active {
      .layout-sidebar {
        // transform: translateX(0);
        left: 0;
      }

      .layout-mask {
        display: block;
      }
    }
  }

  .body-overflow-hidden {
    overflow: hidden;
  }

  .p-paginator button {
    margin: 0 1px !important;
    border-radius: 5px !important;
  }

  .p-paginator {
    padding: 0;
  }

  .agent-filter-section {
    width: 100%;
  }

  .home-header {
    flex-direction: column;
  }

  .user-filter-section {
    flex-direction: column;
  }

  .flex-mobile-column {
    flex-direction: column !important;
  }

  .dialog-mobile {
    width: 80vw !important;
  }

  .mobile-button-group {
    margin-bottom: 0.5rem !important;
  }

  .menu-toggle {
    display: none;
  }

  .layout-main-container{
    margin-left: 0px;
}
}

//smaller than tablet
@media (max-width: 800px) {
  .dialog-mobile {
    width: 100vw !important;
  }
}

@media (max-width: 450px) {
  #mobile_menu {
    width: 10rem; 
  }
}


