.status-container {
  min-width: 18rem;
  width: 18rem;
  text-align: center;
  padding: 0.5rem 2.5rem;
  border-radius: 5px;
  color: var(--primary-color-text);
}

.preapplication-status {
  background: #f3a25a;
}
.inprogress-status {
  background: #ffd875;
}
.notapprovedapplication-status {
  background: #ff9999;
}
.approvedapplication-status {
  background: #95dfb2;
}
.aprstage-status {
  background: #9dd0ff;
}
.waitingforplanning-status {
  background: #8ca300;
}
.paymentnotmade-status {
  background: #ef6262;
}
.paymentpending-status {
  background: #ff823c;
}
.contractsigned-status {
  background: #59bb60;
}
.contractsent-status {
  background: #35a5f6;
}
.offerapproved-status {
  background: #75cfd5;
}
.offersent-status {
  background: #7d82f2;
}
.offerstage-status {
  background: #a895b5;
}
.revisioncompleted-status {
  background: #6ea182;
}
.sentforrevision-status {
  background: #ff9999;
}
.waitingpaymentconfirm-status {
  background: #d173d9;
}
.offerreview-status {
  background: #81a7b3;
}
.aprofficerassigment-status {
  background: #9273d9;
}
.aprreview-status {
  background: #a373d9;
}
.createdcontract-status {
  background: #82b7dd;
}
